<template>
  <bar-chart
    class="trends-chart"
    :value="value"
    :chart-data="chartData"
    :options="chartOptions"
    @input="$emit('input', $event)"
  />
</template>

<script>
import * as math from 'mathjs';
import Vue from 'vue';
import colors from 'vuetify/lib/util/colors';

import * as dateTimeString from '@/utils/dateTimeString';
import { Date } from '@/extensions';

export default Vue.component(
  'trends-chart',
  Vue.extend({
    name: 'TrendsChart',
    props: {
      value: { type: Object, default: null },
      options: { type: Object, default: () => new Object() },
      dateRange: { type: Array, default: null },
      shownIndices: { type: Array, default: () => [0, 1] },
    },
    data: () => ({
      chartData: null,
      metadata: [
        {
          label: '血壓',
          unit: 'mmHg',
          type: 'bar',
          color: colors.purple.darken2,
          axisId: 'bp-y-axis',
          getValue(record) {
            if (record.measuredData)
              return [
                record.measuredData.bloodPressure.systolic,
                record.measuredData.bloodPressure.diastolic,
              ];
            return [];
          },
        },
        {
          label: '心率',
          unit: 'bpm',
          type: 'line',
          color: colors.pink.darken2,
          axisId: 'hr-y-axis',
          getValue(record) {
            if (record.measuredData) return record.measuredData.heartRate;
            return null;
          },
        },
      ],
    }),
    computed: {
      chartOptions() {
        const chartOptions = {
          legend: {
            onClick(/*e, legendItem*/) {
              // TODO: wait for Chart.js v3, enable here, use chart.show/.hide in filter to controll
              // https://github.com/chartjs/Chart.js/pull/7055
              // const index = legendItem.datasetIndex;
              // const ci = this.chart;
              // const meta = ci.getDatasetMeta(index);
              // meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
              // ci.update();
            },
          },
          maintainAspectRatio: false,
          plugins: {
            showValueAroundPoint: false,
          },
          scales: {
            xAxes: [
              {
                id: 'time-x-axis',
                offset: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Date',
                },
                ticks: {
                  autoSkipPadding: 16,
                  callback: (_, index, values) => {
                    const value = values[index];
                    return Date.withoutSeconds(value.value).toLocaleString(
                      undefined,
                      value.major ? { month: 'short', day: '2-digit' } : { hour: 'numeric' },
                    );
                  },
                  major: {
                    enabled: true,
                    fontStyle: 'bold',
                  },
                  maxRotation: 0,
                },
                time: {
                  tooltipFormat: 'LLLL',
                  unit: 'hour',
                },
                type: 'time',
              },
            ],
            yAxes: [
              {
                id: 'bp-y-axis',
                type: 'linear',
                position: 'left',
                scaleLabel: {
                  display: true,
                  labelString: 'Blood Pressure (mmHg)',
                },
              },
              {
                id: 'hr-y-axis',
                type: 'linear',
                position: 'right',
                scaleLabel: {
                  display: true,
                  labelString: 'Heart Rate (bpm)',
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              title: (tooltipItems, data) => {
                return Date.withoutSeconds(data.labels[tooltipItems[0].index]).longLocalizedString;
              },
              label(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                if (typeof tooltipItem.yLabel === 'number') {
                  return `${dataset.label}: ${tooltipItem.yLabel} ${dataset.unit}`;
                } else {
                  const values = JSON.parse(tooltipItem.yLabel);
                  return `${dataset.label}: Sys. ${values[0]}, Dia. ${values[1]} ${dataset.unit}`;
                }
              },
            },
          },
        };
        return Object.assign(chartOptions, this.options);
      },
    },
    watch: {
      dateRange() {
        this.updateChart();
      },
      shownIndices() {
        this.updateChart();
      },
      chartOptions: {
        handler() {
          this.updateChart();
        },
        deep: true,
      },
      ['$store.state.measurementRecords']: {
        handler() {
          this.updateChart();
        },
        deep: true,
      },
    },
    created() {
      this.updateChart();
    },
    methods: {
      updateChart() {
        // TODO: can updateChart be computed?
        this.chartData = { labels: null, datasets: null };
        const records = this.$store.state.measurementRecords;
        if (!records) return;
        const labels = new Array();
        const indices = new Array();
        for (const [i, record] of records.entries()) {
          const measuredAt = record.measuredAt.toDate();
          if (this.dateRange) {
            const dateRange = this.dateRange
              .slice()
              .sort(
                (a, b) => dateTimeString.toDate(a).getTime() - dateTimeString.toDate(b).getTime(),
              );
            const measuredAtTime = measuredAt.getTime();
            const dateFrom = dateTimeString.toDate(dateRange[0]);
            if (measuredAtTime < dateFrom.getTime()) continue;
            const dateTo = dateRange.length > 1 ? dateTimeString.toDate(dateRange[1]) : dateFrom;
            dateTo.setDate(dateTo.getDate() + 1);
            if (measuredAtTime >= dateTo.getTime()) continue;
          }
          indices.push(i);
          labels.push(measuredAt);
        }
        const datasets = new Array();
        const bloodPressureValues = new Array();
        const heartRateValues = new Array();
        this.chartOptions.scales.yAxes.forEach((v) => (v.display = false));
        for (const [i, meta] of this.metadata.entries()) {
          if (this.shownIndices && !this.shownIndices.includes(i)) continue;
          this.chartOptions.scales.yAxes
            .filter((v) => v.id === meta.axisId)
            .forEach((v) => (v.display = true));
          const dataset = {
            backgroundColor: meta.color + '7f',
            borderColor: meta.color + 'ff',
            borderWidth: 1,
            barThickness: 2,
            pointRadius: 1,
            fill: false,
            label: meta.label,
            lineTension: 0,
            yAxisID: meta.axisId,
            type: meta.type,
            data: new Array(),
            unit: meta.unit, // additional
          };
          for (const [i, record] of records.entries()) {
            if (!indices.includes(i)) continue;
            const value = meta.getValue(record);
            if (typeof value === 'number') heartRateValues.push(value);
            else bloodPressureValues.push(...value);
            dataset.data.push(meta.getValue(record));
          }
          datasets.push(dataset);
        }
        if (bloodPressureValues.length > 0 || heartRateValues.length > 0 || this.dateRange) {
          if (bloodPressureValues.length > 0 && heartRateValues.length > 0) {
            const min = math.min(heartRateValues);
            const mid = (math.min(bloodPressureValues) + math.max(heartRateValues)) / 2;
            const max = math.max(bloodPressureValues);
            for (const yAxis of this.chartOptions.scales.yAxes) {
              yAxis.ticks =
                yAxis.id === 'bp-y-axis'
                  ? { min: math.floor(mid - (max - min) * 0.75), max: max }
                  : { min: min, max: math.ceil(mid + (max - min) * 1.25) };
            }
          } else if (bloodPressureValues.length > 0 || heartRateValues.length > 0) {
            const min = math.min(
              bloodPressureValues.length > 0 ? bloodPressureValues : heartRateValues,
            );
            const max = math.max(
              bloodPressureValues.length > 0 ? bloodPressureValues : heartRateValues,
            );
            for (const yAxis of this.chartOptions.scales.yAxes)
              yAxis.ticks = { min: min, max: max };
          }
          this.chartData = { labels, datasets };
        } else {
          // TODO: need test that would chart be updated when data is changed
          // this.chartData = { labels, datasets };
          this.chartData = { labels: new Array(), datasets: new Array() };
        }
      },
    },
  }),
);
</script>
