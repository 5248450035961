<template>
  <div v-if="_.isPlainObject($store.state.profile) && $store.state.patient" class="trends">
    <v-toolbar flat dense>
      <v-row :style="{ flex: `0 0 ${$vuetify.breakpoint.mobile ? (48 + 4) * 1 : 240}px` }">
        <v-menu
          v-model="filterMenuIsOpen"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn
                  :rounded="!$vuetify.breakpoint.mobile"
                  :text="!$vuetify.breakpoint.mobile"
                  :outlined="!$vuetify.breakpoint.mobile"
                  :icon="$vuetify.breakpoint.mobile"
                  class="mr-1"
                  color="success"
                  v-bind="{ ...attrsMenu, ...attrsTooltip }"
                  v-on="{ ...onMenu, ...onTooltip }"
                >
                  <v-icon :left="!$vuetify.breakpoint.mobile">mdi-filter-menu</v-icon>
                  <template v-if="!$vuetify.breakpoint.mobile">過濾</template>
                </v-btn>
              </template>
              <span>過濾</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-subtitle>日期範圍</v-card-subtitle>
            <v-card-text>
              <v-menu
                ref="pickDateRangeMenu"
                v-model="dateRangePickerMenuIsOpen"
                transition="slide-y-transition"
                offset-y
                :close-on-content-click="false"
                :return-value.sync="dateRange"
              >
                <template #activator="{ on, attrs }">
                  <v-btn rounded text block v-bind="attrs" v-on="on">
                    <v-icon left>mdi-calendar-range</v-icon>
                    <span class="subtitle-1 text-capitalize">{{ dateRangeString }}</span>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dateRange"
                  range
                  no-title
                  scrollable
                  :day-format="$_mixin_vDatePicker_dayFormat"
                >
                  <v-btn depressed color="warning" @click="$refs.pickDateRangeMenu.save(null)">
                    清除
                  </v-btn>
                  <v-spacer />
                  <v-btn depressed color="primary" @click="$refs.pickDateRangeMenu.save(dateRange)">
                    確定
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-card-text>
            <v-divider />
            <v-card-subtitle>種類</v-card-subtitle>
            <v-card-text>
              <v-chip-group
                id="chip-group"
                v-model="shownIndices"
                column
                mandatory
                multiple
                class="my-n1"
              >
                <template v-for="(chip, i) in filterChips">
                  <v-chip
                    :key="`filterChips-${i}`"
                    filter
                    :color="chip.color"
                    text-color="white"
                    class="ma-1"
                  >
                    {{ chip.label }}
                  </v-chip>
                </template>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-menu>
        <v-spacer />
      </v-row>
      <v-spacer />
      <v-toolbar-title>趨勢</v-toolbar-title>
      <v-spacer />
      <v-row :style="{ flex: `0 0 ${$vuetify.breakpoint.mobile ? (48 + 4) * 1 : 240}px` }">
        <v-spacer />
      </v-row>
    </v-toolbar>
    <trends-chart id="trends-chart" :date-range="dateRange" :shown-indices="shownIndices" />
  </div>
</template>

<style lang="scss" scoped>
#chip-group::v-deep .v-slide-group__content {
  justify-content: space-around;
}
#trends-chart {
  position: relative;
  height: calc(100vh - 96px);
}
</style>

<script>
import Vue from 'vue';
import colors from 'vuetify/lib/util/colors';

import * as dateTimeString from '@/utils/dateTimeString';
import TrendsChart from '@/components/TrendsChart';
import vDatePicker from '@/mixins/vDatePicker';

export default Vue.extend({
  name: 'Trends',
  components: {
    TrendsChart,
  },
  mixins: [vDatePicker],
  data: () => ({
    filterButtonTooltipShowing: false,
    filterMenuIsOpen: false,
    dateRangePickerMenuIsOpen: false,
    dateRange: null,
    shownIndices: [0, 1],
    filterChips: [
      { label: '血壓', color: colors.purple.darken2 },
      { label: '心率', color: colors.pink.darken2 },
    ],
  }),
  computed: {
    dateRangeString() {
      if (!this.dateRange) return '所有日期';
      const dateRange = this.dateRange
        .slice()
        .sort((a, b) => dateTimeString.toDate(a).getTime() - dateTimeString.toDate(b).getTime());
      let dateRangeString = dateTimeString.toDate(dateRange[0]).shortLocalizedDateString;
      if (dateRange.length > 1) {
        dateRangeString += ' ~ ';
        dateRangeString += dateTimeString.toDate(dateRange[1]).shortLocalizedDateString;
      }
      return dateRangeString;
    },
  },
});
</script>
